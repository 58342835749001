(function($) {
    if ($(".tx-teleroute-profitchecker").length <= 0) return;

    $(document).ready(function(){
        /*** PROFITCHECKER ACCORDION - START ***/
        activePanel = $("#accordion div.panel:first");
        $(activePanel).addClass('active');
        /*** PROFITCHECKER ACCORDION - END ***/
        /*** ROFITCHECKER FIRST CALCULATION - START ***/
        if($('#milesperyear').val()){var milesperyear = $('#milesperyear').val();} else {var milesperyear = $('#milesperyear').attr('placeholder');}
        if($('#profitrate').val()){var profitrate = $('#profitrate').val();} else {var profitrate = $('#profitrate').attr('placeholder');}

        var sum = Math.ceil(parseFloat($('#crosscosts').attr('placeholder')).toFixed(2)) + Math.ceil(parseFloat($('#expenses').attr('placeholder')).toFixed(2));
        $('#costdriver').html(sum);

        var investmenttruck = parseFloat($('#investmenttruck').attr('placeholder')).toFixed(2);
        var rateyear = parseFloat($('#rateyear').attr('placeholder')).toFixed(2);
        var	periodtruck = parseFloat($('#periodtruck').attr('placeholder')).toFixed(2);
        var restvalueVal = Math.ceil(rateyear) * (Math.ceil(investmenttruck) / 100);
        var restvalueVal = Math.ceil(restvalueVal) * Math.ceil(periodtruck);
        var restvalueVal = Math.ceil(investmenttruck) - Math.ceil(restvalueVal);
        $('#restvalue').val(restvalueVal);
        var restvalue = parseFloat($('#restvalue').val()).toFixed(2);

        var costtruck = (investmenttruck - parseFloat(restvalue).toFixed(2)) / periodtruck;
        $('#costtruck').html(costtruck);

        var investmenttrailer = parseFloat($('#investmenttrailer').attr('placeholder')).toFixed(2);
        var rateyeartrailer = parseFloat($('#rateyeartrailer').attr('placeholder')).toFixed(2);
        var	periodtrailer = parseFloat($('#periodtrailer').attr('placeholder')).toFixed(2);
        var restvaluetrailerVal = Math.ceil(rateyeartrailer) * (Math.ceil(investmenttrailer) / 100);
        var restvaluetrailerVal = Math.ceil(restvaluetrailerVal) * Math.ceil(periodtrailer);
        var restvaluetrailerVal = Math.ceil(investmenttrailer) - Math.ceil(restvaluetrailerVal);
        $('#restvaluetrailer').val(restvaluetrailerVal);
        var restvaluetrailer = parseFloat($('#restvaluetrailer').val()).toFixed(2);

        var costtrailer = (investmenttrailer - parseFloat(restvaluetrailer).toFixed(2)) / periodtrailer;
        $('#costtrailer').html(costtrailer);

        var repair = parseFloat($('#repair').attr('placeholder')).toFixed(2);
        var repaircosts = parseFloat($('#repaircosts').attr('placeholder')).toFixed(2);
        var repairoverhead = parseFloat($('#repairoverhead').attr('placeholder')).toFixed(2);
        var othercost = Math.ceil(repair) + Math.ceil(repaircosts) + Math.ceil(repairoverhead);
        $('#othercosts').html(othercost);

        var fuelprice = parseFloat($('#fuelprice').attr('placeholder')).toFixed(2);
        var consumption = parseFloat($('#consumption').attr('placeholder')).toFixed(2);
        var fuelperkm = fuelprice * consumption / 100;
        fuelperkm = fuelperkm * Math.ceil(parseFloat(milesperyear).toFixed(2));

        $('#fuelcosts').html(Math.ceil(fuelperkm));

        var calccostspermonth = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / 12;
        $('#calccostspermonth').html(calccostspermonth);
        var calcCostspermile = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / parseFloat(milesperyear).toFixed(2);
        $('#calcCostspermile').html(calcCostspermile);


        $("#return_1").click(function(event){
            setTabulator($('#step1 .panel'));
        });
        $("#return_2").click(function(event){
            setTabulator($('#step2 .panel'));
        });

        $("#submit_1").click(function(event){
            var form_data = $("#step1").serializeArray();
            var error_free = true;
            for (var input in form_data){
                var element = $("#"+form_data[input]['name']);
                var valid = element.hasClass("valid");
                if(valid && !element.val() || valid && element.val() == element.attr('placeholder')){
                    element.parent().find('.note').fadeIn();
                    element.parent().addClass('error');
                    element.parent().find('.note').delay(2000).fadeOut();
                    error_free = false;
                } else {
                    element.parent().removeClass('error');
                }
            }
            if(!error_free){
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('.error:visible:first').offset().top - 100
                }, 1000);
            } else{
                setTabulator($('#step2 .panel'));
                dataLayer.push({event: "profit_calculator_step_1"});
            }
        });

        $("#submit_2").click(function(event){
            var form_data = $("#step2").serializeArray();
            var error_free = true;
            for (var input in form_data){
                var element = $("#"+form_data[input]['name']);
                var valid = element.hasClass("valid");
                if(valid && !element.val() || valid && element.val() == element.attr('placeholder')){
                    element.parent().find('.note').fadeIn();
                    element.parent().addClass('error');
                    element.parent().find('.note').delay(2000).fadeOut();
                    error_free = false;
                } else {
                    element.parent().removeClass('error');
                }
            }
            if(!error_free){
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('.error:visible:first').offset().top - 100
                }, 1000);
            } else{
                setTabulator($('#step3 .panel'));
                dataLayer.push({event: "profit_calculator_step_2"});
            }
        });

        $("#submit_3").click(function(event){
            var form_data = $("#step3").find(':input').not('#afterPrefix,#phone,#prefix').serializeArray();
            var error_free = true;

            var sEmail = $('#email').val();
            if (sEmail.length == 0) {
                $('#email').parent().find('.note').fadeIn();
                $('#email').parent().addClass('error');
                $('#email').parent().find('.note').delay(2000).fadeOut();
                error_free = false;
            }
            if(!validateEmail(sEmail)) {
                $('#email').parent().find('.note').fadeIn();
                $('#email').parent().addClass('error');
                $('#email').parent().find('.note').delay(2000).fadeOut();
                error_free = false;
            }

            var minLength = 7,
                maxLength = 15,
                afterPrefix = $("#afterPrefix").val();

            if(!afterPrefix) {
                $("#afterPrefix").parent().find('.translation38').fadeIn();
                $("#afterPrefix").parent().addClass('error');
                $("#afterPrefix").parent().find('.translation38').delay(2000).fadeOut();
                error_free = false;
            }

            if (afterPrefix && afterPrefix.length <= minLength) {
                $("#afterPrefix").parent().find('.translation382').fadeIn();
                $("#afterPrefix").parent().addClass('error');
                $("#afterPrefix").parent().find('.translation382').delay(2000).fadeOut();
                error_free = false;
            }

            if (afterPrefix && afterPrefix.length >= maxLength) {
                $("#afterPrefix").parent().find('.translation383').fadeIn();
                $("#afterPrefix").parent().addClass('error');
                $("#afterPrefix").parent().find('.translation383').delay(2000).fadeOut();
                error_free = false;
            }

            if (afterPrefix && afterPrefix.length <= maxLength && afterPrefix.length >= minLength) {
                $("#afterPrefix").parent().removeClass('error');
            }

            for (var input in form_data){
                var element = $("#"+form_data[input]['name']);
                var valid = element.hasClass("valid");
                if(valid && !element.val() || element.hasClass('checkbox') && !element.is(":checked")){
                    element.parent().find('.note').fadeIn();
                    element.parent().addClass('error');
                    element.parent().find('.note').delay(2000).fadeOut();
                    error_free = false;
                }
                else {
                    element.parent().removeClass('error');
                }
            }


            if(!error_free){
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('.error:visible:first').offset().top - 100
                }, 1000);
            } else{
                //*** REST SERVICE CALL ***//
                if($('#milesperyear').val()){var milesperyear = $('#milesperyear').val();} else {var milesperyear = $('#milesperyear').attr('placeholder');}
                if($('#profitrate').val()){var profitrate = $('#profitrate').val();} else {var profitrate = $('#profitrate').attr('placeholder');}
                if($('#crosscosts').val()){var crosscosts = $('#crosscosts').val();} else {var crosscosts = $('#crosscosts').attr('placeholder');}
                if($('#expenses').val()){var expenses = $('#expenses').val();} else {var expenses = $('#expenses').attr('placeholder');}
                var sum = Math.ceil(parseFloat(crosscosts).toFixed(2)) + Math.ceil(parseFloat(expenses).toFixed(2));
                var YEARLY_COST_OF_ONE_DRIVER = sum;

                if($('#investmenttruck').val()){var investmenttruck = parseFloat($('#investmenttruck').val()).toFixed(2);} else {var investmenttruck = parseFloat($('#investmenttruck').attr('placeholder')).toFixed(2);}
                if($('#rateyear').val()){var rateyear = parseFloat($('#rateyear').val()).toFixed(2);} else {var rateyear = parseFloat($('#rateyear').attr('placeholder')).toFixed(2);}
                if($('#restvalue').val()){var restvalue = parseFloat($('#restvalue').val().replace(' ','').replace(',',' ').replace('.', '').replace(' ', '.')).toFixed(2);} else {var restvalue = parseFloat($('#restvalue').attr('placeholder')).toFixed(2);}
                if($('#periodtruck').val()){var periodtruck = parseFloat($('#periodtruck').val()).toFixed(2);} else {var periodtruck = parseFloat($('#periodtruck').attr('placeholder')).toFixed(2);}
                var restvalueVal = Math.ceil(rateyear) * (Math.ceil(investmenttruck) / 100);
                var restvalueVal = Math.ceil(restvalueVal) * Math.ceil(periodtruck);
                var restvalueVal = Math.ceil(investmenttruck) - Math.ceil(restvalueVal);
                var costtruck = (investmenttruck - restvalue) / periodtruck;
                var YEARLY_COST_OF_ONE_TRUCK = costtruck;

                if($('#investmenttrailer').val()){var investmenttrailer = parseFloat($('#investmenttrailer').val()).toFixed(2);} else {var investmenttrailer = parseFloat($('#investmenttrailer').attr('placeholder')).toFixed(2);}
                if($('#rateyeartrailer').val()){var rateyeartrailer = parseFloat($('#rateyeartrailer').val()).toFixed(2);} else {var rateyeartrailer = parseFloat($('#rateyeartrailer').attr('placeholder')).toFixed(2);}
                if($('#restvaluetrailer').val()){var restvaluetrailer = parseFloat($('#restvaluetrailer').val().replace(' ','').replace(',',' ').replace('.', '').replace(' ', '.')).toFixed(2);} else {var restvaluetrailer = parseFloat($('#restvaluetrailer').attr('placeholder')).toFixed(2);}
                if($('#periodtrailer').val()){var periodtrailer = parseFloat($('#periodtrailer').val()).toFixed(2);} else {var periodtrailer = parseFloat($('#periodtrailer').attr('placeholder')).toFixed(2);}
                var restvaluetrailerVal = Math.ceil(rateyeartrailer) * (Math.ceil(investmenttrailer) / 100);
                var restvaluetrailerVal = Math.ceil(restvaluetrailerVal) * Math.ceil(periodtrailer);
                var restvaluetrailerVal = Math.ceil(investmenttrailer) - Math.ceil(restvaluetrailerVal);
                var costtrailer = (investmenttrailer - restvaluetrailer) / periodtrailer;

                if($('#repair').val()){var repair = parseFloat($('#repair').val()).toFixed(2);} else {var repair = parseFloat($('#repair').attr('placeholder')).toFixed(2);}
                if($('#repaircosts').val()){var repaircosts = parseFloat($('#repaircosts').val()).toFixed(2);} else {var repaircosts = parseFloat($('#repaircosts').attr('placeholder')).toFixed(2);}
                if($('#repairoverhead').val()){var repairoverhead = parseFloat($('#repairoverhead').val()).toFixed(2);} else {var repairoverhead = parseFloat($('#repairoverhead').attr('placeholder')).toFixed(2);}
                var othercost = Math.ceil(repair) + Math.ceil(repaircosts) + Math.ceil(repairoverhead);

                if($('#fuelprice').val()){var fuelprice = parseFloat($('#fuelprice').val()).toFixed(2);} else {var fuelprice = parseFloat($('#fuelprice').attr('placeholder')).toFixed(2);}
                if($('#consumption').val()){var consumption = parseFloat($('#consumption').val()).toFixed(2);} else {var consumption = parseFloat($('#consumption').attr('placeholder')).toFixed(2);}
                var fuelperkm = fuelprice * consumption / 100;
                fuelperkm = fuelperkm * Math.ceil(parseFloat(milesperyear).toFixed(2));
                var YEARLY_COST_OF_FUEL = Math.ceil(fuelperkm);

                var calccostspermonth = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / 12;
                var calccostsperyear = sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm);
                var calcCostspermile = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / parseFloat(milesperyear).toFixed(2);
                var COST_PER_KM = calcCostspermile;

                if($('#average').val()){var average = $('#average').val();} else {var average = $('#average').attr('placeholder');}
                var averageCalc1 = 100 + Math.ceil(parseFloat(profitrate).toFixed(2));
                var averageCalc2 = 100 - Math.ceil(parseFloat(average).toFixed(2));
                var averageCalc3 = calcCostspermile * averageCalc1;
                var costvehicle = averageCalc3 / averageCalc2;
                var AVERAGE_EMPTY_RUNNING_PER_VEHICLE = average;
                var COST_PER_VEHICLE = costvehicle;

                if($('#averageintern').val()){var averageintern = $('#averageintern').val();} else {var averageintern = $('#averageintern').attr('placeholder');}
                var averageCalc1 = 100 + Math.ceil(parseFloat(profitrate).toFixed(2));
                var averageCalc2 = 100 - Math.ceil(parseFloat(averageintern).toFixed(2));
                var averageCalc3 = calcCostspermile * averageCalc1;
                var costvehicleintern = averageCalc3 / averageCalc2;
                var AVERAGE_EMPTY_RUNNING_USING_TFX = averageintern;
                var COST_PER_VEHICLE_USING_TFX = costvehicleintern;

                if($('#vehiclenumber').val()){var vehiclenumber = parseFloat($('#vehiclenumber').val()).toFixed(2);} else {var vehiclenumber = parseFloat($('#vehiclenumber').attr('placeholder')).toFixed(2);}
                var milesallvehicles = vehiclenumber * milesperyear;
                if($('#costmonth').val()){var costmonth = parseFloat($('#costmonth').val()).toFixed(2);} else {var costmonth = parseFloat($('#costmonth').attr('placeholder')).toFixed(2);}
                if($('#turnover').val()){var turnover = parseFloat($('#turnover').val()).toFixed(2);} else {var turnover = parseFloat($('#turnover').attr('placeholder')).toFixed(2);}

                var calcProfit1 = costvehicle - costvehicleintern;
                var calcProfit2 = -Math.ceil(parseFloat(costmonth).toFixed(2)) + Math.ceil(parseFloat(turnover).toFixed(2));
                var calcProfit3 = calcProfit1 * Math.ceil(parseFloat(milesperyear).toFixed(2)) * Math.ceil(parseFloat(vehiclenumber).toFixed(2));
                var calcProfit4 = calcProfit2 * 12;
                var profitperkm = Math.ceil(parseFloat(calcProfit3).toFixed(2)) + Math.ceil(parseFloat(calcProfit4).toFixed(2));
                var TOTAL_PROFIT_PER_YEAR = profitperkm;

                var endResult1 = Math.ceil(parseFloat(milesperyear).toFixed(2)) * vehiclenumber;
                var endResult2 = profitperkm / endResult1;

                var TOTAL_COST_PER_KM = COST_PER_KM * vehiclenumber;
                var NUMBER_OF_VEHICLES = vehiclenumber;
                var EXTRA_TURNOVER_USING_TFX = turnover;
                var PROFIT_PER_KM = profitperkm;
                var TOTAL_PROFIT_PER_KM = endResult2;

                if ($('#service').is(':checked')) {
                    var LEAD_CONSENT = 0;
                } else {
                    var LEAD_CONSENT = 1;
                }
                if ($('#terms').is(':checked')) {
                    var TERMS_AND_CONDITIONS = 1;
                } else {
                    var TERMS_AND_CONDITIONS = 0;
                }
                var COMPLETED = 1;

                var lang_value = $('#lang_value').val();
                var country_value = $('#country_value').val();

                var firstname = $('#firstname').val();
                var lastname = $('#lastname').val();
                var company = $('#company').val();
                var email = $('#email').val();
                var phone = $('#phone').val();
                //*** REST SERVICE CALL ***//
                var basePath = window.location.protocol+"//"+window.location.hostname+window.location.pathname.substring(0,7);
                $.ajax({
                    method: "POST",
                    url: basePath+'?type=9991&tx_telerouteprofitchecker_profitchecker%5Baction%5D=calculation&tx_telerouteprofitchecker_profitchecker%5Bcontroller%5D=ProfitChecker',
                    dataType: 'json',
                    data: {
                        lang_value: lang_value,
                        country_value: country_value,
                        firstname: firstname,
                        lastname: lastname,
                        company: company,
                        email: email,
                        phone: phone,
                        YEARLY_COST_OF_ONE_DRIVER: YEARLY_COST_OF_ONE_DRIVER,
                        YEARLY_COST_OF_ONE_TRUCK: YEARLY_COST_OF_ONE_TRUCK,
                        YEARLY_COST_OF_FUEL: YEARLY_COST_OF_FUEL,
                        COST_PER_KM: COST_PER_KM,
                        AVERAGE_EMPTY_RUNNING_PER_VEHICLE: AVERAGE_EMPTY_RUNNING_PER_VEHICLE,
                        COST_PER_VEHICLE: COST_PER_VEHICLE,
                        AVERAGE_EMPTY_RUNNING_USING_TFX: AVERAGE_EMPTY_RUNNING_USING_TFX,
                        COST_PER_VEHICLE_USING_TFX: COST_PER_VEHICLE_USING_TFX,
                        NUMBER_OF_VEHICLES: NUMBER_OF_VEHICLES,
                        EXTRA_TURNOVER_USING_TFX: EXTRA_TURNOVER_USING_TFX,
                        PROFIT_PER_KM: PROFIT_PER_KM,
                        TOTAL_COST_PER_KM: TOTAL_COST_PER_KM,
                        TOTAL_PROFIT_PER_KM: TOTAL_PROFIT_PER_KM,
                        TOTAL_PROFIT_PER_YEAR: TOTAL_PROFIT_PER_YEAR,
                        LEAD_CONSENT: LEAD_CONSENT,
                        TERMS_AND_CONDITIONS: TERMS_AND_CONDITIONS,
                        COMPLETED: COMPLETED,
                        WKTS_LEADSOURCE: $('#wkts_leadsource_value').val()
                    }
                }).always(function(result) {
                    if (result.terms == false) {
                        $('.translation36').addClass('error');
                        $('.note.translation38').fadeIn();
                        return false;
                    }
                    if (result.success == true) {
                        dataLayer.push({event: "profit_calculator_step_3"});
                        $("#accordion").remove();
                        $("#submit-success").fadeIn();
                    } else {
                        $("#accordion").remove();
                        $("#submit-failed").fadeIn();
                    }
                });
                return false;
            }
        });

        $("#terms").click(function(){
            if($(this).is(':checked')){
                $(this).parent().addClass('checked');
            } else {
                $(this).parent().removeClass('checked');
            }
        });

        $("#service").click(function(){
            if($(this).is(':checked')){
                $(this).parent().addClass('checked');
            } else {
                $(this).parent().removeClass('checked');
            }
        });

        $(".currency").each(function(){
            $(this).formatCurrency();
        });
        $(".currency.3dec").each(function(){
            $(this).formatCurrency({
                roundToDecimalPlace: 3
            });
        });
        $(".currency.0dec").each(function(){
            $(this).formatCurrency({
                roundToDecimalPlace: 0
            });
        });

        $(".currency").each(function(){
            if($(this).text()){
                $(this).text($(this).text().replace(',00', ''));
            }
            if($(this).val()){
                $(this).val($(this).val().replace(',00', ''));
            }
        });
        /*** FIRST CALCULATION - END ***/
    });
})(jQuery);

/*** TELEROUTE PROFITCHECKER - FIX HEIGHT END ***/
/*** TELEROUTE PROFITCHECKER - TABULATOR CHANGE START ***/
function setTabulator(e){
    if(!$(e).hasClass('active')){
        var calcwidth = $("#accordion").width() - 100;
        $(".panel.active").animate({width: "50px"}, 300);
        $(e).animate({width: calcwidth}, 300);
        $('#accordion .panel').removeClass('active');
        $('.timeline ol li').removeClass('current');
        $('.timeline ol li').removeClass('complete');
        $(e).addClass('active');
        $(e).parent().prevAll().find('.panel').addClass('complete');
        $(e).parent().nextAll().find('.panel').removeClass('complete');
        $('.timeline ol li.'+$(e).data('section')).addClass('current');
        $('.timeline ol li.'+$(e).data('section')).prevAll().addClass('complete');
    };
    changeHeight();
}

function changeHeight(){
    $('html, body').animate({
        scrollTop: $("#accordion").offset().top - 300
    }, 300);
    setTimeout(function(){
        $('#accordion .panel').each(function(){
            if($(this).hasClass('active')){
                $('#accordion').animate({
                    height: $(this).find('.panelContent').height() + 100
                }, 100, function() {
                });

            }
        });
    },300);
}

/*** TELEROUTE PROFITCHECKER - TABULATOR CHANGE END ***/
/*** TELEROUTE PROFITCHECKER - CALCULATE LIVE START ***/
if($("#average").length > 0){
    $("#average").change(function(){
        if($(this).val() > 99){
            $(this).val('');
            $('.note.failure2').fadeIn();
            setTimeout(function(){
                $('.note.failure2').fadeOut();
            }, 2000);
            calculateNewCosts();
        }
        return false;
    });
    $("#averageintern").change(function(){
        if($('#average').val()){var maxnum = $('#average').val();} else {var maxnum = $('#average').attr('placeholder');}
        if($(this).val() > parseInt(maxnum)){
            $(this).val('');
            $('.note.failure').fadeIn();
            setTimeout(function(){
                $('.note.failure').fadeOut();
            }, 1000);
            calculateNewCosts();
        }
        return false;
    });
}
function calculateNewCosts(){
    let hubspotFomReady = false;
    if ($(".hbspt-form form").length > 0) hubspotFomReady = true;

    if($('#milesperyear').val()){var milesperyear = $('#milesperyear').val();} else {var milesperyear = $('#milesperyear').attr('placeholder');}
    $('#milesperyearR').html(milesperyear);
    if($('#profitrate').val()){var profitrate = $('#profitrate').val();} else {var profitrate = $('#profitrate').attr('placeholder');}
    if($('#crosscosts').val()){var crosscosts = $('#crosscosts').val();} else {var crosscosts = $('#crosscosts').attr('placeholder');}
    if($('#expenses').val()){var expenses = $('#expenses').val();} else {var expenses = $('#expenses').attr('placeholder');}
    $('#proftirateR').html(profitrate+' %');
    $('#proftirateR2').html(profitrate+' %');

    var sum = Math.ceil(parseFloat(crosscosts).toFixed(2)) + Math.ceil(parseFloat(expenses).toFixed(2));
    $('#costdriver').html(sum);
    // YEARLY_COST_OF_ONE_DRIVER
    if (hubspotFomReady) $(".hbspt-form form input[name='yearly_cost_of_one_driver']").val(sum);

    if($('#investmenttruck').val()){var investmenttruck = parseFloat($('#investmenttruck').val()).toFixed(2);} else {var investmenttruck = parseFloat($('#investmenttruck').attr('placeholder')).toFixed(2);}
    if($('#rateyear').val()){var rateyear = parseFloat($('#rateyear').val()).toFixed(2);} else {var rateyear = parseFloat($('#rateyear').attr('placeholder')).toFixed(2);}
    if($('#restvalue').val()){var restvalue = parseFloat($('#restvalue').val().replace(' ','').replace(',',' ').replace('.', '').replace(' ', '.')).toFixed(2);} else {var restvalue = parseFloat($('#restvalue').attr('placeholder')).toFixed(2);}
    if($('#periodtruck').val()){var periodtruck = parseFloat($('#periodtruck').val()).toFixed(2);} else {var periodtruck = parseFloat($('#periodtruck').attr('placeholder')).toFixed(2);}

    var restvalueVal = Math.ceil(rateyear) * (Math.ceil(investmenttruck) / 100);
    var restvalueVal = Math.ceil(restvalueVal) * Math.ceil(periodtruck);
    var restvalueVal = Math.ceil(investmenttruck) - Math.ceil(restvalueVal);
    $('#restvalue').val(restvalueVal);

    var costtruck = (investmenttruck - restvalue) / periodtruck;
    $('#costtruck').html(costtruck);
    // YEARLY_COST_OF_ONE_TRUCK
    if (hubspotFomReady) $(".hbspt-form form input[name='yearly_cost_of_one_truck']").val(costtruck);

    if($('#investmenttrailer').val()){var investmenttrailer = parseFloat($('#investmenttrailer').val()).toFixed(2);} else {var investmenttrailer = parseFloat($('#investmenttrailer').attr('placeholder')).toFixed(2);}
    if($('#rateyeartrailer').val()){var rateyeartrailer = parseFloat($('#rateyeartrailer').val()).toFixed(2);} else {var rateyeartrailer = parseFloat($('#rateyeartrailer').attr('placeholder')).toFixed(2);}
    if($('#restvaluetrailer').val()){var restvaluetrailer = parseFloat($('#restvaluetrailer').val().replace(' ','').replace(',',' ').replace('.', '').replace(' ', '.')).toFixed(2);} else {var restvaluetrailer = parseFloat($('#restvaluetrailer').attr('placeholder')).toFixed(2);}
    if($('#periodtrailer').val()){var periodtrailer = parseFloat($('#periodtrailer').val()).toFixed(2);} else {var periodtrailer = parseFloat($('#periodtrailer').attr('placeholder')).toFixed(2);}

    var restvaluetrailerVal = Math.ceil(rateyeartrailer) * (Math.ceil(investmenttrailer) / 100);
    var restvaluetrailerVal = Math.ceil(restvaluetrailerVal) * Math.ceil(periodtrailer);
    var restvaluetrailerVal = Math.ceil(investmenttrailer) - Math.ceil(restvaluetrailerVal);
    $('#restvaluetrailer').val(restvaluetrailerVal);

    var costtrailer = (investmenttrailer - restvaluetrailer) / periodtrailer;
    $('#costtrailer').html(costtrailer);

    if($('#repair').val()){var repair = parseFloat($('#repair').val()).toFixed(2);} else {var repair = parseFloat($('#repair').attr('placeholder')).toFixed(2);}
    if($('#repaircosts').val()){var repaircosts = parseFloat($('#repaircosts').val()).toFixed(2);} else {var repaircosts = parseFloat($('#repaircosts').attr('placeholder')).toFixed(2);}
    if($('#repairoverhead').val()){var repairoverhead = parseFloat($('#repairoverhead').val()).toFixed(2);} else {var repairoverhead = parseFloat($('#repairoverhead').attr('placeholder')).toFixed(2);}
    var othercost = Math.ceil(repair) + Math.ceil(repaircosts) + Math.ceil(repairoverhead);
    $('#othercosts').html(othercost);

    if($('#fuelprice').val()){var fuelprice = parseFloat($('#fuelprice').val()).toFixed(2);} else {var fuelprice = parseFloat($('#fuelprice').attr('placeholder')).toFixed(2);}
    if($('#consumption').val()){var consumption = parseFloat($('#consumption').val()).toFixed(2);} else {var consumption = parseFloat($('#consumption').attr('placeholder')).toFixed(2);}
    var fuelperkm = fuelprice * consumption / 100;
    fuelperkm = fuelperkm * Math.ceil(parseFloat(milesperyear).toFixed(2));
    $('#fuelcosts').html(Math.ceil(fuelperkm));
    // YEARLY_COST_OF_FUEL
    if (hubspotFomReady) $(".hbspt-form form input[name='yearly_cost_of_fuel']").val(Math.ceil(fuelperkm));

    var calccostspermonth = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / 12;
    var calccostsperyear = sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm);
    $('#calccostspermonth').html(calccostspermonth);
    $('#calccostsperyearR').html(parseFloat(calccostsperyear).toFixed(2));
    $('#calccostspermonthR').html(calccostspermonth);
    var calcCostspermile = (sum + costtruck + costtrailer + othercost + Math.ceil(fuelperkm)) / parseFloat(milesperyear).toFixed(2);
    $('#calcCostspermile').html(calcCostspermile);
    // COST_PER_KM
    if (hubspotFomReady) $(".hbspt-form form input[name='cost_per_km']").val(calcCostspermile);

    $('#calcCostspermileR').html(calcCostspermile);

    // if($('#average').val()) {var average = $('#average').val();} else {var average = $('#average').attr('placeholder');}
    var average = $('#average').attr('placeholder');
    if($('#average').val()) {
        average = $('#average').val();
    }
    // AVERAGE_EMPTY_RUNNING_PER_VEHICLE
    if (hubspotFomReady) $(".hbspt-form form input[name='average_empty_running_per_vehicle']").val(average);

    var averageCalc1 = 100 + Math.ceil(parseFloat(profitrate).toFixed(2));
    var averageCalc2 = 100 - Math.ceil(parseFloat(average).toFixed(2));
    var averageCalc3 = calcCostspermile * averageCalc1;
    var costvehicle = averageCalc3 / averageCalc2;
    $('#costvehicle').html(costvehicle);
    // COST_PER_VEHICLE
    if (hubspotFomReady) $(".hbspt-form form input[name='cost_per_vehicle']").val(costvehicle);

    $('#averageR').html(average+' %');
    $('#costvehicleR').html(costvehicle);
    $('#costvehicleR2').html(costvehicle);

    if($('#averageintern').val()){var averageintern = $('#averageintern').val();} else {var averageintern = $('#averageintern').attr('placeholder');}
    var averageCalc1 = 100 + Math.ceil(parseFloat(profitrate).toFixed(2));
    var averageCalc2 = 100 - Math.ceil(parseFloat(averageintern).toFixed(2));
    var averageCalc3 = calcCostspermile * averageCalc1;
    var costvehicleintern = averageCalc3 / averageCalc2;
    $('#costvehicleintern').html(costvehicleintern);
    // COST_PER_VEHICLE_USING_TFX
    if (hubspotFomReady) $(".hbspt-form form input[name='cost_per_vehicle_using_tfx']").val(costvehicleintern);


    $('#averageinternR').html(averageintern+' %');
    $('#costvehicleinternR').html(costvehicleintern);

    // if($('#vehiclenumber').val()){var vehiclenumber = parseFloat($('#vehiclenumber').val()).toFixed(2);} else {var vehiclenumber = parseFloat($('#vehiclenumber').attr('placeholder')).toFixed(2);}
    var vehiclenumber = 1.0;
    if (hubspotFomReady) {
        let tmpVN = $(".hbspt-form form input[name='number_of_vehicles']").val();
        if (tmpVN) vehiclenumber = parseFloat(tmpVN).toFixed(0);
    }

    // if($('#vehiclenumber').val()){var vehiclenumberR = parseFloat($('#vehiclenumber').val()).toFixed(0);} else {var vehiclenumberR = parseFloat($('#vehiclenumber').attr('placeholder')).toFixed(0);}
    var vehiclenumberR = vehiclenumber;
    $('#vehiclesR').html(vehiclenumberR);

    var milesallvehicles = vehiclenumber * milesperyear;
    $('#milesallvehiclesR').html(milesallvehicles);

    // if($('#costmonth').val()){var costmonth = parseFloat($('#costmonth').val()).toFixed(2);} else {var costmonth = parseFloat($('#costmonth').attr('placeholder')).toFixed(2);}
    var costmonth = 1.0;
    if (hubspotFomReady) {
        let tmpCM = $(".hbspt-form form input[name='cost_of_teleroute']").val();
        if (tmpCM) costmonth = parseFloat(tmpCM).toFixed(0);
    }

    // if($('#turnover').val()){var turnover = parseFloat($('#turnover').val()).toFixed(2);} else {var turnover = parseFloat($('#turnover').attr('placeholder')).toFixed(2);}
    var turnover = 1.0;
    if (hubspotFomReady) {
        let tmpTO = $(".hbspt-form form input[name='extra_turnover_using_tfx']").val();
        if (tmpTO) turnover = parseFloat(tmpTO).toFixed(0);
    }

    $('#costmonthR').html(costmonth);
    $('#turnoverR').html(turnover);

    var calcProfit1 = costvehicle - costvehicleintern;
    var calcProfit2 = -Math.ceil(parseFloat(costmonth).toFixed(2)) + Math.ceil(parseFloat(turnover).toFixed(2));
    var calcProfit3 = calcProfit1 * Math.ceil(parseFloat(milesperyear).toFixed(2)) * Math.ceil(parseFloat(vehiclenumber).toFixed(2));
    var calcProfit4 = calcProfit2 * 12;
    var profitperkm = Math.ceil(parseFloat(calcProfit3).toFixed(2)) + Math.ceil(parseFloat(calcProfit4).toFixed(2));
    $('#profit').html(profitperkm);
    $('#profitR').html(profitperkm);
    // TOTAL_PROFIT_PER_YEAR
    if (hubspotFomReady) $(".hbspt-form form input[name='total_profit_per_year']").val(profitperkm);

    var endResult1 = Math.ceil(parseFloat(milesperyear).toFixed(2)) * vehiclenumber;
    var endResult2 = profitperkm / endResult1;
    $('#yourprofit').html(endResult2);
    $('#yourprofitR').html(endResult2);
    // PROFIT_PER_KM
    if (hubspotFomReady) $(".hbspt-form form input[name='profit_per_km']").val(endResult2);

    $(".currency").each(function(){
        $(this).formatCurrency();
    });
    $(".currency.3dec").each(function(){
        $(this).formatCurrency({
            roundToDecimalPlace: 3
        });
        if($(this).text().substr($(this).text().length - 3) == '0 €'){
            $(this).text($(this).text().slice(0, -3)+' €');
        }
    });
    $(".currency.0dec").each(function(){
        $(this).formatCurrency({
            roundToDecimalPlace: 0
        });
    });
    $(".numberformats").each(function(){
        $(this).formatCurrency({symbol: ''});
        if($(this).text()){
            $(this).text($(this).text().replace(',00', ''));
        }
        if($(this).val()){
            $(this).val($(this).val().replace(',00', ''));
        }
    });

    $(".currency").each(function(){
        if($(this).text()){
            $(this).text($(this).text().replace(',00', ''));
        }
        if($(this).val()){
            $(this).val($(this).val().replace(',00', ''));
        }
    });
}

$('.tx-teleroute-profitchecker').on('keydown', 'input.nummeric', function(e){-1!==$.inArray(e.keyCode,[46,8,9,27,13,110,188,190])||(/65|67|86|88/.test(e.keyCode)&&(e.ctrlKey===true||e.metaKey===true))&&(!0===e.ctrlKey||!0===e.metaKey)||35<=e.keyCode&&40>=e.keyCode||(e.shiftKey||48>e.keyCode||57<e.keyCode)&&(96>e.keyCode||105<e.keyCode)&&e.preventDefault()});
$('.tx-teleroute-profitchecker input.nummeric').on('change', function(){
    var parseNum = $(this).val().replace(',','.');
    $(this).val(parseNum);
    calculateNewCosts();
});
/*** TELEROUTE PROFITCHECKER - CALCULATE LIVE START ***/

