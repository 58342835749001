//////////////////////////////
// ADD SCROLLED & BACKTOTOP //
//////////////////////////////
$(window).scroll(function() {
	if(window.innerWidth < 1080){
		$('#topNav').height(window.innerHeight - 40);
	} else {
		$('#topNav').css('height', 'auto');
	}
	var scroll = $(window).scrollTop();
	if (scroll >= 0) {
		$("body").addClass("scrolled");
	} else {
		$("body").removeClass("scrolled");
	}
	if(window.innerWidth > 1080){
		var heightoftop = $('#mainHeader > .bgGray').height();
		if(scroll > heightoftop){
			$('#mainHeader #navWrapper').addClass('fixedNav');
		} else {
			$('#mainHeader #navWrapper').removeClass('fixedNav');
		}
	}
});
// ADD SCROLLED CSS CLASS - End //


//////////////////////////////
// DOCUMENT READY FUNCTIONS //
//////////////////////////////
$(document).ready(function(){

	if($(window).width() < 789){
		$(".b2b-form .signUpForm legend").click(function(event){
			if(!$(this).hasClass('active')){
				$('.b2b-form .signUpForm legend').toggleClass('active');
				$('.b2b-form .signUpForm fieldset.step').slideToggle();
				$('.b2b-form .signUpForm .hidden-mobile').slideToggle();
			}
		});
	}

	/*** CHANGE NAV HEIGHT ***/
	if(window.innerWidth < 1080){
		$('#topNav').height(window.innerHeight);
	}
	/*** CHANGE NAV HEIGHT ***/

	/*** MOBILE BURGER NAV ***/
	$(document).on('click','#burger',function(){
		if($('#burger').hasClass('close-x')){
			$('#burger').removeClass('close-x');
			$('#topNav').fadeOut();
			$('#topNav').removeClass('active');
			$('#burgerContainer').removeClass('active');
		}
		else{
			$('#burger').addClass('close-x');
			$('#topNav').fadeIn();
			$('#topNav').addClass('active');
			$('#burgerContainer').addClass('active');
		}
	});

	$("#topNav #webshopCTA").on("click", function() {
		if($('#burger').hasClass('close-x')){
			$("#burgerContainer #burger").click();
		}
	});

	$('.imgBgHeader .offerTagElWrap .listBlock a[href*=\\#]:not([href=\\#])').on('click', function(){
		if ( $(this).is('[class^="btn_"]') ) {
			// var formLink = this.hash;
			$(this).closest('.imgBgHeader').find('.hubSpotFormContainer .hsFormWrap .hs-form-field:first-child input').focus();
			$(this).closest('.imgBgHeader').find('.hubSpotFormContainer .hsFormWrap .hs-form-field:first-child').addClass('input-focus');
		}
	});

	/*** MOBILE BURGER NAV ***/

	/*** IFRAME MAP ***/
	$(document).on('click','#map',function(){
		$('#map iframe').css("pointer-events", "auto");
	});
	$( "#map" ).mouseleave(function() {
		$('#map iframe').css("pointer-events", "none");
	});
	/*** IFRAME MAP ***/

	/*** LANGUAGE MENU ***/
	$(document).on('click','#langMenue',function(){
		if($('#langMenue').hasClass('active')){
			$('#langMenue').removeClass('active');
			$('#langMenue ul').slideUp();
		}
		else{
			$('#langMenue').addClass('active');
			$('#langMenue ul').slideDown();
		}
	});
	$(document).on('click','#langMenue2',function(){
		if($('#langMenue2').hasClass('active')){
			$('#langMenue2').removeClass('active');
		}
		else{
			$('#langMenue2').addClass('active');
		}
	});
	$(document).bind("mouseup touchend", function (e){
		var langMenue = $("#langMenue.active");
		if (!langMenue.is(e.target) && langMenue.has(e.target).length === 0) {
			$('#langMenue').removeClass('active');
			$('#langMenue ul').slideUp();
		}

		var langMenue2 = $("#langMenue2.active");
		if (!langMenue2.is(e.target) && langMenue2.has(e.target).length === 0) {
			$('#langMenue2').removeClass('active');
		}
	});

	$(document).on('click','#countryOpen',function(){
		if($('#countryWrapper').hasClass('active')){
			$('#countryWrapper').removeClass('active');
			$('#countryWrapperNav').slideUp();
		}
		else{
			$('#countryWrapper').addClass('active');
			$('#countryWrapperNav').slideDown();
		}
	});

	$(document).on('click','#countryWrapperNav a',function(){
		$('section.country-contact').hide();
		$('section.country-contact.country-'+$(this).data('uid')).fadeIn();
		$('#countryWrapper').removeClass('active');
		$('#countryWrapperNav').slideUp();
	});

	$(document).bind("mouseup touchend", function (e){
		var langMenue = $("#langMenue.active");
		if (!langMenue.is(e.target) && langMenue.has(e.target).length === 0) {
			$('#langMenue').removeClass('active');
			$('#langMenue ul').slideUp();
		}
	});
	/*** LANGUAGE MENU ***/

	/*** SUBPAGE MENU ***/
	$('.sub_caret').click(function(e){
		e.preventDefault();
		if($(this).hasClass('isOpen')){
			let parentObj = $(this).closest('li');
			parentObj.removeClass("open");

			$(this).removeClass('isOpen');
		} else{
			let parentObj = $(this).closest('li');
			parentObj.addClass("open");

			$(this).addClass('isOpen');
		}
	});
	/*** SUBPAGE MENU ***/

	/*** SECTION ACCORDEON HEADER ***/
	$('.accordeon .question .header').click(function(e){
		if(!$(this).hasClass('active')){
			$('.accordeon .question .header').removeClass('active');
			$('.accordeon .answer').slideUp();
			var uid = $(this).data('uid');
			$(this).toggleClass('active');
			$('#block'+uid).slideToggle();
		} else {
			var uid = $(this).data('uid');
			$(this).toggleClass('active');
			$('#block'+uid).slideToggle();
		}
	});
	/*** SECTION ACCORDEON HEADER ***/
})

setInterval(function() {
	$(".fade-inline-images").each(function(){
		if($(this).find('.slide:visible').is(':last-child')){
			$(this).find('.slide:visible').hide();
			$(this).find('.slide:first').fadeIn(1000);
		} else {
			$(this).find('.slide:visible')
				.hide()
				.next('.slide')
				.fadeIn(1000);
		}
	});
},  3000);

$('.testimonialSlider').slick({
	infinite: true,
	dots: false,
	arrows: true,
	slidesToShow: 2,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1400,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint:  900,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: true
			}
		}
	]
});

$('.testimonialSlider.single').slick('unslick');
$('.testimonialSlider.single').slick({
	infinite: false,
	dots: true,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1
});

$('.mobileSlider').slick({
	dots: false,
	infinite: false,
	speed: 300,
	slidesToShow: 4,
	slidesToScroll: 4,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 2000,
				dots: false
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 2000,
				dots: false
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 2000,
				dots: false
			}
		}
	]
});


$('.customer-slider-wrapper').slick({
	infinite: true,
	dots: true,
	arrows: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				arrows: false
			}
		}
	]
});

$('.brand-slider').slick({
	arrows: true,
	dots: true,
	infinite: false,
	slidesToScroll: 1,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 1025,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 769,
			settings: {
				slidesToShow: 1.15,
				arrows: false,
			}
		}
	]
});

if (window.matchMedia("(max-width: 768px)").matches) {
	$('.js-iconTxtSliderWrap-slick-wrapper').slick({
		arrows: false,
		dots: true,
		slidesToShow: 1
	});
}



/* SCROLLING */
$(window).bind('scroll', function() {
	if ($(window).scrollTop() > 0) {
		$('#mainHeader').addClass('headerScroll');
	} else {
		$('#mainHeader').removeClass('headerScroll');
	}
});
/* SCROLLING */

/* WINDOW RESIZE */
$(window).resize(function(){
	if(window.innerWidth < 1080){
		$('#topNav').height(window.innerHeight);
	} else {
		$('#topNav').css('height', 'auto');
	}
	var videoWi = $('.videoFrame').width();
	$('.videoFrame').css('height', (videoWi/1.9) + 'px');

	var inner = $('#accordion .panel.active').height();
	$('#accordion .panel').each(function(){
		if(!$(this).hasClass('active')){
			$(this).height(inner);
		}
	});
}).resize();
/* Window resize */


/*** TELEROUTE PROFITCHECKER - FIX HEIGHT START ***/
$(window).on("load", function() {

	setTimeout(function(){
		var activeHeight = $('#accordion .panel.active').innerHeight();
		$('#accordion .panel').each(function(){
			if(!$(this).hasClass('active')){
				$(this).animate({
					height: activeHeight
				}, 100, function() {
				});
			}
		});
		if($('#openChatBox > a').css('display') != 'none'){
			$('#openChatBox').fadeIn();
		}
		if($(window).width() > 900){
			var selector = $('.bgGray .paddingMin');
			var heights = [];
			selector.each(function(){
				var height = $(this).height();
				heights.push(height);
			});
			var maxHeight = Math.max.apply(null, heights);
			selector.each(function(){
				$(this).height(maxHeight);
			});
		}
	},300);

	var selector = $('.col-md-11 .fontSize2');
	var heights = [];
	selector.each(function(){
		var height = $(this).height();
		heights.push(height);
	});
	var maxHeight = Math.max.apply(null, heights);
	selector.each(function(){
		$(this).height(maxHeight);
	});

	// var selector = $('.col-xs-6 p.description');
	// var heights = [];
	// selector.each(function(){
	// 	var height = $(this).height();
	// 	heights.push(height);
	// });
	// var maxHeight = Math.max.apply(null, heights);
	// selector.each(function(){
	// 	$(this).height(maxHeight);
	// });

});

function validateEmail(sEmail) {
	var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
	if (filter.test(sEmail)) {
		return true;
	}
	else {
		return false;
	}
};

$( document ).ready(function() {
	$('#topNav .hidden-md-up > a').click(function () {
		$(this).next('.tx-news-lanugage-manager').find('ul').slideToggle('fast');
		$(this).next('.tx-news-lanugage-manager').find('ul').toggleClass('open');
	});

	$('.booking').click(function() {
		var target = $('.form-container'),
			headerHeight = $("#mainHeader").height() + 50; // Get fixed header height

		$('html,body').animate({
			scrollTop: target.offset().top - headerHeight
		}, 1000);
		return false;
	});

	$('.booking_webshop').click(function() {
		var target = $('.webshop-plans'),
			headerHeight = $("#mainHeader").height() + 50; // Get fixed header height

		$('html,body').animate({
			scrollTop: target.offset().top - headerHeight
		}, 1000);
		return false;
	});
});


// lazy load (detect if it's NOT IE or EDGE Browser)
document.addEventListener("DOMContentLoaded", function() {
	const imageObserver = new IntersectionObserver(function(entries, imgObserver) {
		entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				const lazyImage = entry.target
				lazyImage.src = lazyImage.dataset.img
				lazyImage.classList.remove("lazy-img");
				imgObserver.unobserve(lazyImage);
			}
		})
	});
	const arr = document.querySelectorAll('img.lazy-img')
	arr.forEach(function(v) {
		imageObserver.observe(v);
	})

	// bg image lazy load
	const bgObserver = new IntersectionObserver(function(entries, imgObserver) {
		entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				const lazyImageBG = entry.target
				loadBackground();
				bgObserver.unobserve(lazyImageBG);
			}
		})
	});

	const arrBg = document.querySelectorAll('.lazy-paper');
	arrBg.forEach(function(v) {
		bgObserver.observe(v);
	})
	//
});


// bg image lazy load
function loadBackground() {
	var imgDeferSection = document.querySelectorAll('.lazy-paper');
	var styleDiv = "background-image: url({url})";
	for (var i = 0; i < imgDeferSection.length; i++) {
		imgDeferSection[i].setAttribute('style', styleDiv.replace("{url}", imgDeferSection[i].getAttribute('data-img')));
	}

}

$(document).ready(function(){
	if($(window).width() < 767){
		$("#mainHeader.sea + #page1 > .header_1.sectionBgBig.formMail input, #mainHeader.sea + #page1 > .header_1.sectionBgBig.formMail select").on('click touchstart',function(){
			$('#mobileFooter').hide();
		});
		$("#mainHeader.sea + #page1 > .header_1.sectionBgBig.formMail input, #mainHeader.sea + #page1 > .header_1.sectionBgBig.formMail select").focusout(function() {
			$('#mobileFooter').show();
		});
	}

	$('#websop-plan').click(function (e) {
		if ($(this).is(':checked')) {
			$('.webshop-plan-box.start').removeClass('show').addClass('overlapped');
			$('.webshop-plan-box.startPlus').removeClass('overlapped').addClass('show');
		} else {
			$('.webshop-plan-box.startPlus').removeClass('show').addClass('overlapped');
			$('.webshop-plan-box.start').removeClass('overlapped').addClass('show');
		}
	});

	//Add parameter to static link
	if (window.location.href.indexOf("?") > -1) {
		var webshopButton = $('.webshop-plans .btn_red');
		if (webshopButton) {
			webshopButton.attr('href', function(index, value) {
				return value + '?' +decodeURIComponent(window.location.search.substring(1));
			});
		}
	}

});


(function($) {
	/*
	* SubHeader Slider
	*/
	$(window).load(function() {
	// $(document).ready(function() {

		if($(window).width() < 991){
			const topPageSlider = $('.slider_header_banner');
			topPageSlider.find('.slider_container').slick({
				dots: false,
				slidesToShow: 3,
				slidesToScroll: 1,

				responsive: [
					{
						breakpoint: 990,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		}


		//Counter logic
		var totalSavedToday = 0;
		// var dailyBaseNumber = 22000;
		dailyBaseNumberMin = 350000;
		dailyBaseNumberMax = 353000;
		var dailyBaseNumber = Math.floor(Math.random() * (dailyBaseNumberMax - dailyBaseNumberMin + 1)) + dailyBaseNumberMin;
		var numberDivider = [3, 5, 7];
		var counter = 0;
		totalSavedToday = Math.round((dailyBaseNumber + 1));
		window.setInterval(function () {
			if(totalSavedToday < 353000) {
				totalSavedToday = Math.round(totalSavedToday + numberDivider[counter]);
				$("#savingsValue").html(numberWithCommas(totalSavedToday));
				counter++;
				if (counter >= 3) counter = 0;
			}
		}, 2000);
		function numberWithCommas(x) {
			var htmlClass = $('html');
			if (htmlClass.hasClass('de_site') || htmlClass.hasClass('nl_site')) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			} else if (htmlClass.hasClass('fr_site')) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			} else {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		}

		//hs-forms
		setTimeout(function(){
			$(".hbspt-form input").on('keyup',function () {
				$(this).parent().parent().addClass("addText");
				if ($(this).val() == "") {
					$(this).parent().parent().removeClass("input-has-value");
				} else {
					$(this).parent().parent().addClass("input-has-value");
				}
			});
			$(".hbspt-form input").on('blur',function () {
				$(this).parent().parent().removeClass("input-focus");
				$(this).parent().parent().removeClass("addText");
				if ($(this).val() != "") {
					$(this).parent().parent().addClass("input-has-value");
				} else {
					$(this).parent().parent().removeClass("input-has-value");
				}
			});
			$(".hbspt-form input").on('focus', function () {
				$(this).parent().parent().addClass("input-focus");
			});
		},1000);

		$('.btn_cta_arc').each(function() {
			$(this).wrapInner('<span></span>');
		});

	});
})(jQuery);