document.addEventListener("DOMContentLoaded", function(loadedEvent) {
	jQuery(function($) {
		// $("form .intl-tel-input").each(function(telInput){
		// 	var $phoneContainer = $(this);
		// 	var $phoneInput = $('input[type=tel]', $phoneContainer);
		// 	var $formWithPhone = $phoneInput.closest("form");
		// 	var defaultCountry = $phoneInput.intlTelInput("getSelectedCountryData");

		// 	$phoneInput.on('focusout change', function(e) {
		// 		var selectedCountry = $phoneInput.intlTelInput("getSelectedCountryData");

		// 		if($phoneInput.val().length >= 5) {
		// 			if(selectedCountry == undefined || selectedCountry.iso2 == undefined || $phoneInput.val().startsWith('+' + selectedCountry.dialCode) == false) {
		// 				$phoneInput.val('+' + defaultCountry.dialCode);
		// 				$phoneInput.intlTelInput("setCountry", defaultCountry.iso2);
		// 				$phoneInput.trigger('countrychange');
		// 			}
		// 		}
		// 	});

		// 	$formWithPhone.on('submit', function(e) {
		// 		var selectedCountry = $phoneInput.intlTelInput("getSelectedCountryData");

		// 		if(selectedCountry == undefined || selectedCountry.iso2 == undefined || $phoneInput.val().startsWith('+' + selectedCountry.dialCode) == false) {
		// 			e.preventDefault();
		// 			$phoneInput.trigger('change');
		// 			return false;
		// 		}
		// 	});
		// });

		// for responsive table
		if ($(".content-only > table").length > 0) {
			$(".content-only > table").each(function() {
				$( "<div class='table-responsive'></div>" ).insertAfter( $(this) );
				$(this).addClass("table");
				let wraper = $(this).next();
				wraper.append($(this));
			});
		}

		// freight sampler

		// $("#fs-contactform").on("submit", function(){
		// 	let formObj = $(this);
		// 	if (formObj[0].checkValidity()) {
		// 		// console.log("submit event + valid");
		// 		if ($(".submitLoading", formObj).length <= 0) {
		// 			$("input[type='submit']", formObj).parent().append("<div class='submitLoading'><div class='loader'></div></div>");
		// 		}
		// 		formObj.addClass("submited");
		// 	}
		// });

		function moveToContactFromSection() {
			var targetOffset = document.querySelector("section.freightSampleWrap").offsetTop;
			$('html,body').animate({
				scrollTop: targetOffset
			}, {
				duration: 800,
				step: function( now, fx ){
					let newTargetOffset = document.querySelector("section.freightSampleWrap").offsetTop;
					if (targetOffset != newTargetOffset) {
						targetOffset = newTargetOffset;
						fx.end = targetOffset;
					}
				},
				complete: function () {
					$(".freightSampleWrap .freightSamplerPopup").addClass("open");
					$("html, body").addClass("noScroll");
				}

			});
		}

		function appendListData(mainObj) {
			try {
				let listDataHtml = $(".listingWrap .listingData", mainObj).html();

				if (listDataHtml) {
					listDataJson = JSON.parse(atob(listDataHtml.trim()));

					let desktopTableObj = $(".listingWrap .desktopVersion table", mainObj);
					let mobileListingObj = $(".listingWrap .mobileListingWrap", mainObj);

					if ($("tbody tr", desktopTableObj).length > 0) {
						listDataJson.forEach(route => {

							// desktop listing
							let newDesktopRowObj = $("tbody tr:first", desktopTableObj).clone();

							$(".locationWrap.origin .flagIcon", newDesktopRowObj).removeClass().addClass("flagIcon "+route.originFlag);
							$(".locationWrap.origin .locationName", newDesktopRowObj).html(route.origin);

							$(".locationWrap.destination .flagIcon", newDesktopRowObj).removeClass().addClass("flagIcon "+route.destFlag);
							$(".locationWrap.destination .locationName", newDesktopRowObj).html(route.destination);

							$(".typeLbl", newDesktopRowObj).html(route.type);

							$(".truckInfoWrap .icon", newDesktopRowObj).removeClass().addClass("icon "+route.truckIcon);
							$(".truckInfoWrap .label", newDesktopRowObj).html(route.truckLbl);

							desktopTableObj.append(newDesktopRowObj);


							// mobile listing
							let newMobileItemObj = $(".routeInfoWrap:first", mobileListingObj).clone();

							$(".locationWrap.origin .flagIcon", newMobileItemObj).removeClass().addClass("flagIcon "+route.originFlag);
							$(".locationWrap.origin .locationName", newMobileItemObj).html(route.origin);

							$(".locationWrap.destination .flagIcon", newMobileItemObj).removeClass().addClass("flagIcon "+route.destFlag);
							$(".locationWrap.destination .locationName", newMobileItemObj).html(route.destination);

							$(".typeLbl", newMobileItemObj).html(route.type);

							$(".truckInfoWrap .icon", newMobileItemObj).removeClass().addClass("icon "+route.truckIcon);
							$(".truckInfoWrap .label", newMobileItemObj).html(route.truckLbl);

							mobileListingObj.append(newMobileItemObj);
						});
					}
				}

			} catch (e) {
				console.log("Exception: FS List Data: ", e);
			}
		}

		function initFsFunc(mainObj) {

			let tableWrapObj = $(".listingWrap .desktopVersion", mainObj);

			function tableAnimate() {

				if (tableWrapObj.hasClass('scrolled')) return;

				let tableRows = $("table tr", tableWrapObj).length;

				if (tableRows > 8) {
					let wrapHeight = tableWrapObj.height();
					let wrapScrollTop = tableWrapObj.scrollTop();
					let tableHeight = $("table", tableWrapObj).height();
					let maxScroll = tableHeight-wrapHeight;
					let totalScrollTime = (tableRows-9)*3000;
					let animationTime = (totalScrollTime/maxScroll)*(maxScroll-wrapScrollTop);

					// console.log(maxScroll, totalScrollTime, wrapHeight, tableHeight, wrapScrollTop);
					// console.log(animationTime);

					tableWrapObj.animate({scrollTop:maxScroll}, animationTime, "linear", function(){
						console.log("finished");
						tableWrapObj.addClass("scrolled");
					});
				}

			}
			var timeoutHandler = null;

			$("table", tableWrapObj).on("scroll", function(){
				tableWrapObj.stop();
				clearTimeout(timeoutHandler);
			});
			$("table", tableWrapObj).on("mouseenter", function(){
				tableWrapObj.stop();
				clearTimeout(timeoutHandler);
			});
			$("table", tableWrapObj).on("mouseleave", function(){
				timeoutHandler = setTimeout(function() {
					tableAnimate();
				}, 2000);
			});

			timeoutHandler = setTimeout(function() {
				tableAnimate();
			}, 200);


			function reloadSlider () {
				let listingObj = $('.mobileListingWrap', mainObj);

				if (window.innerWidth >= 1024) {
					if (listingObj.hasClass("slick-initialized")) {
						listingObj.slick('unslick');
					}

					listingObj.removeClass('tablet');
					listingObj.removeClass('mobile');
				} else {
					if (window.innerWidth < 786) {
						if (!listingObj.hasClass('mobile')) {

							if (listingObj.hasClass("slick-initialized")) {
								listingObj.slick('unslick');
							}

							listingObj.addClass("mobile")
							listingObj.removeClass('tablet');

							listingObj.slick({
								infinite: false,
								autoplay: true,
								autoplaySpeed: 2500,
								slidesToShow: 1.15,
								slidesToScroll: 1,
							});
						}
					} else {
						if (!listingObj.hasClass('tablet')) {
							if (listingObj.hasClass("slick-initialized")) {
								listingObj.slick('unslick');
							}
							listingObj.addClass("tablet")
							listingObj.removeClass('mobile');

							listingObj.slick({
								infinite: false,
								autoplay: true,
								autoplaySpeed: 2500,
								slidesToShow: 2.15,
								slidesToScroll: 2,
							});
						}
					}
				}
			}

			$(window).on("resize", reloadSlider);
			reloadSlider();
		}

		if ($(".freightSampleWrap").length > 0) {

			let mainObj = $(".freightSampleWrap");

			$(window).on("load", function() {
				window.setTimeout(() => {
					appendListData(mainObj);

					initFsFunc(mainObj);
				}, 5000);
			});


			let modelObj = $(".freightSamplerPopup", mainObj);

			$(mainObj).on("click", ".btn.infoBtn", function(e) {
				e.preventDefault();
				modelObj.addClass("open");
				$("html, body").addClass("noScroll");

				// let fsFormObj = document.querySelector("form#fs-contactform");
				// if (fsFormObj) {
				// 	modelObj.addClass("open");
				// 	$("html, body").addClass("noScroll");
				// } else {
				// 	let formSection = document.querySelector("section#contact");
				// 	if (formSection) {
				// 		let targetOffset = formSection.offsetTop;
				// 		$('html,body').animate({
				// 			scrollTop: targetOffset
				// 		}, {
				// 			duration: 800,
				// 			step: function( now, fx ){
				// 				let newTargetOffset = document.querySelector("section#contact").offsetTop;
				// 				if (targetOffset != newTargetOffset) {
				// 					targetOffset = newTargetOffset;
				// 					fx.end = targetOffset;
				// 				}
				// 			}
				// 		});
				// 	} else {
				// 		modelObj.addClass("open");
				// 		$("html, body").addClass("noScroll");
				// 	}
				// }
			});

			function closePopup() {
				modelObj.removeClass("open");
				modelObj.removeClass("success");
				modelObj.removeClass("error");

				// $('form', modelObj).trigger("reset");
				// $('form #fs-PHONE', modelObj).trigger("keyup");
				// $('form select, form input[type=checkbox]', modelObj).trigger("change");

				// $('form input.valid', modelObj).removeClass("valid");
				// $('form input.error + label.error', modelObj).hide();
				// $('form input.error', modelObj).removeClass("error");

				// $('form', modelObj).removeClass("submited");

				$("html, body").removeClass("noScroll");
			}

			$(modelObj).click(function() {
				// closePopup();
				modelObj.addClass("static-modal");
				setTimeout(function() {
					modelObj.removeClass("static-modal");
				}, 100);
			});

			$(".innerWrap", modelObj).click(function(e) {
				e.stopPropagation();
			});

			$(".closeBtn", modelObj).click(function() {
				closePopup();
			});

			// contentFormAutoFillScroll2();
		}

		// $("#contactform").submit(function() {
		// 	const url = new URL(window.location.href);
		// 	url.searchParams.set('form_submitted', 'true');
		// 	window.history.replaceState(null, null, url);
		// });
	});
});

// check and scroll to the contact form base on query parameter
function contentFormAutoFillScroll2() {

	if ($("section#contact").length <= 0 && $("form#fs-contactform").length > 0) {

		let urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has('ID')) {
			// $('html,body').animate({
			// 	scrollTop: $("section#contact").offset().top
			// }, 500);

			var targetOffset = document.querySelector("section.freightSampleWrap").offsetTop;
			$('html,body').animate({
				scrollTop: targetOffset
			}, {
				duration: 800,
				step: function( now, fx ){
					let newTargetOffset = document.querySelector("section.freightSampleWrap").offsetTop;
					if (targetOffset != newTargetOffset) {
						targetOffset = newTargetOffset;
						fx.end = targetOffset;
					}
				},
				complete: function () {
					// $(".freightSampleWrap .freightSamplerPopup").addClass("open");
					// $("html, body").addClass("noScroll");
				}

			});
		}
	}
}


/* testimonial slider */
;(function($) {
	$(document).ready(function() {
		let testnlObj = $(".testimonialSliderSection .testmlSliderWrap");
		if (testnlObj.length > 0) {
			$(".pagWrap .dot", testnlObj).click(function() {
				let val = $(this).data("val");
				testnlObj.removeClass("show1");
				testnlObj.removeClass("show2");
				testnlObj.removeClass("show3");

				testnlObj.addClass("show"+val);
			});
		}
		// , picture[data-id="1642"]
		// $('picture[data-id="1396"]').closest('.sectionBgBig').css('display','none');
	});
})(jQuery);


/* black friday banner */
;(function($) {
	$(document).ready(function() {
		let bannerObj = $(".headerBlackFridayBanner");
		if (bannerObj.length > 0) {

			let countDownTime = new Date("2022-11-29T00:00:00.000+01:00").getTime();

			var x = setInterval(function() {

				var now = new Date().getTime();
				var distance = countDownTime - now;

				if (distance < 0) {
					clearInterval(x);

					$(".timeWrap .day .timer",bannerObj).html("0");
					$(".timeWrap .hour .timer",bannerObj).html("00");
					$(".timeWrap .mint .timer",bannerObj).html("00");
					$(".timeWrap .sec .timer",bannerObj).html("00");
				} else {
					// Time calculations for days, hours, minutes and seconds
					var days = Math.floor(distance / (1000 * 60 * 60 * 24));
					var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = Math.floor((distance % (1000 * 60)) / 1000);

					$(".timeWrap .day .timer",bannerObj).html(days);
					$(".timeWrap .hour .timer",bannerObj).html(hours);
					$(".timeWrap .mint .timer",bannerObj).html(minutes);
					$(".timeWrap .sec .timer",bannerObj).html(seconds);
				}
			}, 1000);
		}
	});
})(jQuery);